import { createStore } from 'vuex';
import router from '@/router';
import fetch from '@/fetch.js';

export default createStore({
  state: {
    pid:null,
    name:null,
    fid:null,
    site:'DynoSite',
    discord_inv:null,
    msg:{}
  },
  mutations: {
    setBulk(state,obj) {
      Object.entries(obj).forEach(([key,value])=>state[key] = value);
    }
  },
  actions: {
    async login( { commit }, obj) {
      commit('setBulk', obj);
      await router.replace(document.location.pathname);
      commit('setBulk',{
        msg:{success:`Hello ${obj.name}. Enjoy your stay.`}
      });
    },
    async relogin( { commit }, obj) {
      commit('setBulk', obj);
      await router.replace(document.location.pathname);
      if (obj.name)
        commit('setBulk',{
          msg:{success:`Welcome back ${obj.name}. Enjoy your stay.`}
        });
    },
    async logout({ commit },success='You have successfully logged out.') {
      const { status } = await fetch('/api/v1/logout', {method:'POST'});
      if (status === 'ok') {
        await router.push('/'); // Forcibly return home
        commit('setBulk',{
          pid:null,
          name:null,
          fid:null,
          discord_inv:null,
          msg:{success}
        });
      }
      return { status };
    }
  },
  modules: {
  }
})
