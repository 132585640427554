import { createRouter, createWebHistory } from 'vue-router';
import NotFound from '@/components/NotFound';
import store from '@/store';
import Home from '@/views/Home';
import { MyRouteErr } from './error';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
  },
  {
    meta: {private:true},
    path:'/rank/battlestats',
    name:'Battlestat',
    component:() => import(/* webpackChunkName: "rankBattlestats" */ '@/views/ranks/Battlestats.vue')
  },
  {
    meta: {private:true},
    path:'/rank/crimestats',
    name:'Crime Ranks',
    component:() => import(/* webpackChunkName: "rankBattlestats" */ '@/views/ranks/Crime.vue')
  },
  {
    meta: {private:true},
    path:'/rank/contributions',
    name: 'Contribution',
    component:() => import(/* webpackChunkName: "rankContributions" */ '@/views/ranks/Contributions.vue')
  },
  {
    meta: {private:true},
    path:'/train',
    name: 'Train',
    component:() => import(/* webpackChunkName: "train" */ '@/views/Train.vue')
  },
  {
    meta: {private:true},
    path: '/chain/total',
    name: 'Chain Total',
    component:() => import(/* webpackChunkName: "chainTot" */ '@/views/ChainTotal.vue'),
  },
  {
    meta: {private:true},
    path: '/chain/current',
    name: 'Current Chain',
    component:() => import(/* webpackChunkName: "chainCurr" */ '@/views/ChainCurrent.vue'),
  },
  {
    meta: {private:true},
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settingsBase" */ '@/views/Settings.vue'),
    children:[
      {
        path:'user',
        name:'User Settings',
        alias: '',
        component:() => import(/* webpackChunkName: "settingsUser" */ '@/views/settings/UserSettings.vue')
      },
      {
        path:'faction',
        name: 'Faction Settings',
        component:() => import(/* webpackChunkName: "settingsFaction" */ '@/views/settings/FactionSettings.vue')
      },
    ]
  },
  {
    path:'/:notFound+',
    name: 'NotFound',
    component:NotFound,
    meta: {
      error:'Page Not Found'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
router.onError((error)=>{
  if (error instanceof MyRouteErr) {
    store.commit('setBulk',{msg:{error}});
  }
  return 'test';
})
router.beforeEach(function(to, from, next){
  document.title = 'DynoSite | ' + to.name || 'DynoSite';
  store.commit('setBulk',{
    msg:{
      error:null,
      success:null
    }
  });
  if (to.meta.private) {
    if (!store.state.pid) throw new MyRouteErr('Must be logged in');
  }
  if (to.meta.error && !(to.meta.admin)) {
    throw new MyRouteErr(to.meta.error, to.fullPath);
  }
  next();
  return true;
})

export default router
