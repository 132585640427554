import store from '@/store'

export default function (urlIn, { method, data, formData } ) {
  // URL Builder
  const url = new URL(urlIn,location.href);
  // Options Builder
  const fetchOptions = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method,
  };
  const workingData = {...data};
  formData?.forEach((val,key)=>workingData[key] = val);
  if (method.toLowerCase() === 'get') {
    Object.entries(workingData).forEach(([key,value])=>{
      if (value instanceof Array)
        value.forEach(each=>url.searchParams.append(key,each));
      else
        url.searchParams.append(key,value);
    });
  } else if (method.toLowerCase() === 'post') {
    fetchOptions.body = JSON.stringify(workingData);
  }
  return fetch(url, fetchOptions).then(async r=>({... await r.json(),code:r.status})).catch(()=>{
    store.commit('setBulk',{
      gibberish:true
    });
  })
}
