<template>
  <h2 class="display-2">Welcome to {{site}}</h2>
  <div v-if="!pid">
    This is a custom built website designed to help monitor your faction during chaining periods. Contact <a href="https://www.torn.com/profiles.php?XID=1043377" target="_blank">Dyno</a> for obtaining access to this site.
  </div>
  <div v-else>
    <p>You are now logged in as {{name}} [{{pid}}]. We hope you enjoy your stay.</p>
    <h3>Tutorials</h3>
    <ul>
      <li>Ranks
        <ul>
          <li><b>Battle Stat Rank</b> - The are three differences between this page and tornstats.<br>
          <br>
          1. This page shows you where you sit on the ladder.<br>
          2. This page updates daily.<br>
          3. This page shows you how much you have climbed/fallen since the previous week.<br>
          <br>
          Your battle stats are not stored/exposed/logged in any way outside of tornstats. The ranking is based purely on total battle stats (not "score").</li>
        </ul>
      </li>
      <li>Chain
        <ul>
          <li><b>Current Chain</b> - Tracks the current members that are activly participating in the chain, including how long ago they were seen making a successful hit, and the average respect made from non-milestone hits.</li>
          <li><b>Total Report</b> - Tracks the overall activity across a specified chaining period. This includes Drug and Points usage, a quick calcuator to detect misuage of the aformentioned, merits for Carnage, Massacre, and Genocide, and how many hits required for this faction (settings are managed by those with permissions in the Settings menu).</li>
        </ul>
      </li>
      <li>Train
        <ul>
          <li>This page shows how much energy you have trained between the dates and times set and also the energy trained per day.</li>
        </ul>
      </li>
      <li>Useful Links
        <ul>
          <li>Here you will find useful links that will direct you to our faction forums and other external torn websites.</li>
        </ul>
      </li>
      <li>Settings
        <ul>
          <li><b>User Settings</b> - The place to manage the settings pertaining to your user (there is currently only deleting your account).</li>
          <li><b>Faction Settings</b> - The place to manage the settings pertaining to your faction chaining period.</li>
          <li><b>Train Settings</b> - The place to manage the settings pertaining to your faction training peeiod.</li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
// @ is an alias to /src
import {mapState} from 'vuex';

export default {
  name: 'Home',
  computed:{
    ...mapState(['site','pid','name'])
  }
}
</script>