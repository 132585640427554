<template>
  <transition name="modal">
    <div class="modal fade show" id="modalForm" tabindex="-1" @click.self="$emit('close')">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ site }} Login</h5>
            <button type="button" class="btn-close" @click="$emit('close')" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="login" autocomplete="off">
              <div class="alert alert-danger mb-3" role="alert" v-if="error">
                {{error}}
              </div>
              <div class="mb-3">
                <label class="form-label" for="api_key">API Key</label>
                <div class="input-group">
                  <input :type="showPassType" class="form-control" id="api_key" name="api_key" placeholder="API Key" autocomplete="on" />
                  <button type="button" class="btn btn-danger" v-if="showPass" @click="toggleApiShow">
                    <IconEyeFill/>
                  </button>
                  <button type="button" class="btn btn-primary" v-else @click="toggleApiShow">
                    <IconEyeSlashFill/>
                  </button>
                </div>
              </div>
              <div class="mb-3 form-check">
                <input type="checkbox" class="form-check-input" id="rememberMe"/>
                <label class="form-check-label" for="rememberMe">Remember me</label>
              </div>
              <div class="modal-footer d-block">
                <button type="submit" class="btn btn-primary float-end">Login</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <div class="modal-backdrop fade show"></div>
</template>

<style lang="scss" scoped>
.modal {
  display:block;
}

/*
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
*/
</style>
<script type="text/javascript">
  import fetch from '@/fetch.js';
  import { mapState } from 'vuex';
  // import IconSearch from '@/components/icons/IconSearch'
  import IconEyeFill from '@/components/icons/IconEyeFill'
  import IconEyeSlashFill from '@/components/icons/IconEyeSlashFill'

  export default {
    components:{
      // IconSearch,
      IconEyeFill,
      IconEyeSlashFill
    },
    data(){
      return{
        error:null,
        showPass:false
      }
    },
    computed:{
      ...mapState(['site']),
      showPassType(){
        return this.showPass ? 'text' : 'password';
      }
    },
    methods:{
      toggleApiShow(){
        this.showPass = !this.showPass;
      },
      async login(event){
        this.error = null;
        const formData = new FormData(event.target);
        const result = await fetch('/api/v1/login',{
          method:'post',
          formData
        });
        if ('error' in result)
          this.error = result?.error;
        else
          this.$emit('login',result);
      },
    },
    emits: ['close','login']
  }
</script>
