<template>
  <LoginModal v-if="showLoginModal" @close="showLoginModal = false" @login="login" />
  <header>
    <!-- Fixed navbar -->
    <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
      <div class="container-fluid">
        <AppLink class="navbar-brand" @click="navShow=false;" to="/">{{ site }}</AppLink>
        <button class="navbar-toggler" type="button" @click="navShow=!navShow" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" :class="{'show':navShow}" id="navbarCollapse">
          <ul class="navbar-nav me-auto mb-2 mb-md-0">
            <li class="nav-item">
              <AppLink class="nav-link" activeClass="active" @click="navShow=false;" aria-current="page" to="/">
                <img class="nav-icon" src="@/assets/Home.png" />
                Home
              </AppLink>
            </li>
            <li class="nav-item dropdown" v-if="fid" @mouseleave="rankDropdown = false">
              <span class="nav-link dropdown-toggle" @mouseover.self="rankDropdown = true" id="rankMenuLink" role="button" aria-expanded="false">
                <img class="nav-icon" src="@/assets/Ranks.png" />
                Ranks
              </span>
              <ul class="dropdown-menu dropdown-menu-dark" :class="{'show':rankDropdown}" aria-labelledby="rankMenuLink">
                <li><AppLink class="dropdown-item" @click.self="navShow=false;rankDropdown=false;" to="/rank/battlestats">Battlestat</AppLink></li>
                <li><AppLink class="dropdown-item" @click.self="navShow=false;rankDropdown=false;" to="/rank/crimestats">Crime Ranks</AppLink></li>
                <li><AppLink class="dropdown-item" @click.self="navShow=false;rankDropdown=false;" to="/rank/contributions">Contribution</AppLink></li>
              </ul>
            </li>
            <li class="nav-item dropdown" v-if="fid" @mouseleave="dropdownShow = false">
              <span class="nav-link dropdown-toggle" @mouseover.self="dropdownShow = true" id="navbarDropdownMenuLink" role="button" aria-expanded="false">
                <img class="nav-icon" src="@/assets/Current_Chain.png" />
                Chain
              </span>
              <ul class="dropdown-menu dropdown-menu-dark" :class="{'show':dropdownShow}" aria-labelledby="navbarDropdownMenuLink">
                <li><AppLink class="dropdown-item" @click.self="navShow=false;dropdownShow=false;" to="/chain/current">Current Chain</AppLink></li>
                <li><AppLink class="dropdown-item" @click.self="navShow=false;dropdownShow=false;" to="/chain/total">Total Report</AppLink></li>
              </ul>
            </li>
            <li class="nav-item" v-if="fid">
              <AppLink class="nav-link" activeClass="active" @click="navShow=false;" aria-current="page" to="/train">
                <img class="nav-icon" src="@/assets/Training.png" />
                Train
              </AppLink>
            </li>
            <li class="nav-item dropdown" @mouseleave="dropdownShow2 = false">
              <span class="nav-link dropdown-toggle" @mouseover.self="dropdownShow2 = true" id="navbarDropdownMenuLink2" role="button" aria-expanded="false">
                <img class="nav-icon" src="@/assets/Useful_links.png" />
                Useful Links
              </span>
              <ul class="dropdown-menu dropdown-menu-dark" :class="{'show':dropdownShow2}" aria-labelledby="navbarDropdownMenuLink2">
                <li><AppLink class="dropdown-item" @click.self="navShow=false;dropdownShow2=false;" to="https://beta.tornstats.com/">Torn Stats</AppLink></li>
                <li><AppLink class="dropdown-item" @click.self="navShow=false;dropdownShow2=false;" to="https://yata.yt/">YATA</AppLink></li>
                <li><AppLink class="dropdown-item" @click.self="navShow=false;dropdownShow2=false;" to="https://www.torn.com/forums.php#/p=threads&f=999&t=16322267&b=1&a=8500">Drag Rota</AppLink></li>
                <li><AppLink class="dropdown-item" @click.self="navShow=false;dropdownShow2=false;" to="https://www.torn.com/forums.php#/p=threads&f=999&t=16289047&b=1&a=8500">RW Sign Up</AppLink></li>
                <li><AppLink class="dropdown-item" @click.self="navShow=false;dropdownShow2=false;" to="https://www.torn.com/forums.php#/p=threads&f=999&t=16319397&b=1&a=8500">Respect Gains & Finding Targets</AppLink></li>
              </ul>
            </li>
          </ul>
          <div class="btn-group" role="group">
            <!--<button type="button" class="btn btn-secondary">Left</button>-->
            <AppLink type="button" class="btn btn-secondary" v-if="pid" @click="navShow=false;$event.target.blur()" to="/settings">Settings</AppLink>
            <AppLink :to="`https://discord.gg/${discord_inv}`" type="button" @click="$event.target.blur()" class="btn btn-secondary" v-if="discord_inv">Faction Discord</AppLink>
            <a type="button" class="btn btn-danger" v-if="pid" @click="logout">Logout</a>
            <a type="button" class="btn btn-primary" v-else  @click="loginButton">Login</a>
          </div>
        </div>
      </div>
    </nav>
  </header>
  <!--
    <div>
      {{test_return}}
    </div>
    <div id="nav">
      <AppLink to="/">Home</AppLink> |
      <AppLink to="/about">About</AppLink>
    </div>
  -->
  <main class="flex-shrink-0">
    <div class="container" v-if="!hidden">
      <div class="alert alert-danger" role="alert" v-if="gibberish">
        CRITICAL ERROR: The website is speaking gibberish!
        <hr>
        Please contact <AppLink to="https://www.torn.com/profiles.php?XID=1043377">Dyno [1043377]</AppLink> and please tell him the error message you see. I promise that he will respond to you with a resolution.
      </div>
      <template v-else>
        <div class="alert alert-danger" role="alert" v-if="msg.error">
          Navigation Error: {{msg.error.message}}
          <pre v-if="msg.error.alt">{{ msg.error.alt }}</pre>
          <hr>
          If you believe this is an issue, please contact <AppLink to="https://www.torn.com/profiles.php?XID=1043377">Dyno [1043377]</AppLink> and tell him the error message you see.
        </div>
        <div class="alert alert-primary" role="alert" v-if="msg.success">
          {{msg.success}}
        </div>
      </template>

      <router-view/>
    </div>
  </main>
</template>

<style>
.nav-icon {
  height: 20px;
}
</style>

<script type="text/javascript">
  import(/* webpackChunkName: "boostrap" */ '@/style/bootstrap.scss')
  import {mapState} from 'vuex';
  import LoginModal from '@/components/LoginModal';
  import AppLink from '@/router/AppLink';
  import fetch from '@/fetch';

  export default {
    components:{
      LoginModal,
      AppLink
    },
    mounted(){
      //Localstorage watcher
      const prefix = 'dynoSite-';
      const watcher = ({key,newValue}) => {
        if (key.startsWith(prefix))
          this.$store.state[key.replace(prefix,'')] = newValue;
      };
      this.$local.watchPassive('set',watcher);
      this.$local.watchPassive('remove',watcher);
      this.$store.subscribe(({payload})=>{
        Object.entries(payload).forEach(([key,value])=>{
          if (['pid','name','fid','discord_inv','msg'].includes(key))
            this.$local[value === null?'remove':'set'](prefix+key,value);
        });
      });

      // Login Function
      fetch('/api/v1/test',{method:'get'}).then(data=>{
        this.$store.dispatch('relogin',{
          ...data
        });
      }).finally(()=>{
        this.hidden = false;
      });
    },
    data(){
      return {
        showLoginModal:false,
        navShow:false,
        hidden:true,
        rankDropdown:false,
        dropdownShow:false,
        dropdownShow2:false
      }
    },
    computed:{
      ...mapState(['site','pid','fid','discord_inv','msg','gibberish'])
    },
    methods:{
      async logout(){
        await this.$store.dispatch('logout');
      },
      loginButton(){
        this.showLoginModal = true;
        this.navShow=false;
      },
      login ({pid,name,fid,discord_inv}) {
        this.showLoginModal = false;

        this.$store.dispatch('login',{
          pid,name,fid,discord_inv
        });
      },
    }
  }
</script>
